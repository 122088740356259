import React from "react"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"

// data とすることでExportの対象だったQueryの結果を反映できる。

const ProjectTemplate = ({ pageContext: { title }, data }) => {
  return (
    <main className="project-template-main">
      <h2>{title}</h2>
      <p>{data.contentfulProject.description.description}</p>
    </main>
  )
}

export default ProjectTemplate

export const query = graphql`
  query getSingleProject($title: String) {
    contentfulProject(title: { eq: $title }) {
      description {
        description
      }
      image {
        gatsbyImageData
      }
      title
      id
    }
  }
`
